<template>
  <div class="subscription">
    <Header />
    <HeaderBottom />
    <template v-if="statusTrx == 'waiting confirm'">
      <b-container class="subscription-container">
        <b-row class="justify-content-center">
          <div class="col-lg-9 col-md-6">
            <b-card class="p-4">
              <div class="d-block text-center">
                <img
                  src="@/assets/img/icons/transactions/checked-blue.svg"
                  alt=""
                />
                <h1 class="header-modal p-4 my-2">
                  Anda sudah melakukan Pembayaran
                </h1>
                <b-row class="justify-content-center">
                  <b-button
                    to="/orders"
                    block
                    class="mt-3 mx-2 btn-tertiary py-2"
                    >Aktivitas Dalam Proses</b-button
                  >
                </b-row>
              </div>
            </b-card>
          </div>
        </b-row>
      </b-container>
    </template>

    <template v-else>
      <b-container class="subscription-container">
        <b-row class="justify-content-md-start back-nav">
          <div class="col-lg-12 col-md-6">
            <b-link to="/orders"
              ><b-icon class="mx-2" icon="chevron-left"></b-icon
              ><span>Kembali</span></b-link
            >
          </div>
        </b-row>

        <b-row class="justify-content-md-center p-4">
          <b-card class="subscription-checkout">
            <b-form>
              <div class="checkout-timer mb-4">
                <b-row class="p-2 mb-n3">
                  <div
                    class="col-lg-6 col-md-6 my-auto text-center text-lg-left"
                  >
                    <p class="variant">Menunggu Pembayaran</p>
                    <!-- <span id="idStatus" class="variant">{{status}}</span> -->
                  </div>
                  <div
                    class="col-lg-6 col-md-6 my-auto text-center text-lg-right"
                  >
                    <!-- <p class="value"><b-icon class="mx-2" icon="alarm"></b-icon>23:59:23</p> -->
                    <p class="value">
                      <b-icon class="mx-2" icon="alarm"></b-icon>{{ time }} WIB
                    </p>
                  </div>
                </b-row>
              </div>

              <div class="bank-transfer">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <h4>Transfer Bank</h4>
                  </div>
                </b-row>
              </div>
              <!-- <div class="custodian-bank">
              <b-row class="mt-2">
                <p
                  class="custodian-bank-name"
                  v-text="bank_find(product.id_bank_custodian)"
                ></p>
              </b-row>
            </div> -->
              <hr />
              <div
                class="bank-number mb-2"
                v-for="(value, index) in product.rekening"
                :key="index"
              >
                <b-row class="mt-2">
                  <div class="col-9 my-auto">
                    <div class="custodian-bank-name" ref="noRekCustodian">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 mb-2">
                          <template>
                            <div v-if="value.bank.logo == null">
                              <img
                                :src="
                                  'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                  value.fund_name
                                "
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="`${imageCore}/${value.bank.logo}`"
                                alt="Fund Image"
                                width="60px"
                              />
                            </div>
                          </template>
                        </div>
                        <div class="col-lg-9 col-md-6">
                          <template>
                            <p class="variant my-auto" ref="noRekCustodian">
                              {{
                                value.bank.bank_name
                                  ? value.bank.bank_name
                                  : "-"
                              }}
                            </p>
                            <p class="variant my-auto" ref="noRekCustodian">
                              {{
                                value.account_number
                                  ? value.account_number
                                  : "-"
                              }}
                            </p>

                            <p
                              class="variant d-none"
                              id="copyAccountNumber"
                              ref="noRekCustodian"
                            >
                              {{
                                value.account_number != null
                                  ? replaceChar(value.account_number)
                                  : "-"
                              }}
                            </p>
                          </template>
                        </div>
                        <div class="col-lg-3 col-md-6">
                          <template></template>
                        </div>
                        <div class="col-lg-9 col-md-6">
                          <template>
                            <p
                              class="custodian-bank-name"
                              id="copyAccountNumber"
                              ref="noRekCustodian"
                            >
                              {{
                                value.account_name ? value.account_name : "-"
                              }}
                            </p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 my-auto text-right">
                    <vs-button
                      @click="
                        [(copyAccountNumberPopup = true), copyAccountNumber()]
                      "
                      class="btn-copy-text"
                      type="filled"
                      >Salin</vs-button
                    >
                  </div>
                </b-row>
              </div>
              <hr />
              <div class="total-transfer">
                <b-row class="mt-2">
                  <div class="col-6 my-auto text-left">
                    <p class="variant">Jumlah Transfer</p>
                    <p class="total">
                      {{ activities_subcription.product.fund_ccy }}
                      <template v-if="activities_subcription.fee_sales != null">
                        <span class="total">
                          {{
                            formatPrice(activities_subcription.amount_nominal)
                          }}
                        </span>
                        <span id="copy-text" class="total d-none">
                          {{ activities_subcription.amount_nominal }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="total">
                          {{
                            formatPrice(activities_subcription.amount_nominal)
                          }}
                        </span>
                        <span id="copy-text" class="total d-none">
                          {{ activities_subcription.amount_nominal }}
                        </span>
                      </template>
                    </p>
                  </div>
                  <div class="col-6 my-auto text-right">
                    <vs-button
                      @click="
                        [(copyToClipboardPopup = true), copyToClipboard()]
                      "
                      class="btn-copy-text"
                      type="filled"
                      >Salin</vs-button
                    >
                  </div>
                </b-row>
              </div>

              <div class="upload-proof mt-4">
                <div class="bank-title">
                  <b-row class="justify-content-md-start mb-2">
                    <div class="ekyc-form-upload">
                      <h3>Upload Bukti Pembayaran</h3>
                      <b-row>
                        <b-col>
                          <template>
                            <div class="dropbox" v-if="!previewProof">
                              <span class="mb-4">{{ messageProof }}</span>
                              <div v-if="errorsProof" class="errors">
                                <p
                                  v-for="(error, index) in errorsProof"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                              <div>
                                <input
                                  type="file"
                                  ref="file"
                                  id="file"
                                  class="input-file"
                                  @change="fileImageProof"
                                />
                              </div>
                            </div>
                            <b-row class="justify-content-center">
                              <div class="col-lg-12 col-md-6">
                                <div class="preview" v-if="previewProof">
                                  <img :src="previewProof" />
                                  <b-row class="mt-2">
                                    <b-button
                                      class="reset-button btn-dark px-4 py-2"
                                      @click="resetImageProof"
                                    >
                                      Ganti
                                    </b-button>
                                  </b-row>
                                </div>
                              </div>
                            </b-row>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-row>
                </div>
              </div>
              <div class="notes mb-4">
                <b-row>
                  <p>Pastikan foto bukti pembayaran jelas dan terbaca</p>
                </b-row>
                <b-row>
                  <div class="error my-2" v-if="needToUpload">
                    <p>*Upload bukti pembayaran terlebih dahulu.</p>
                  </div>
                </b-row>
              </div>
              <div class="fund-name mt-2">
                <b-row>
                  <div class="col-2 col-lg-1 text-left mb-2">
                    <div v-if="activities_subcription.product.image == null">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          activities_subcription.product.fund_name
                        "
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="`${imageCore}/images/products/${activities_subcription.product.image}`"
                        alt="Fund Image"
                      />
                    </div>
                  </div>
                  <div class="col-9 px-4">
                    <h4>
                      {{ activities_subcription.product.fund_name }}
                    </h4>
                    <p
                      v-text="
                        activities_subcription.product.investment_manager
                          .im_name
                      "
                    ></p>
                  </div>
                </b-row>
              </div>

              <div class="purchase-review">
                <b-row class="mt-4">
                  <div class="col-6 my-auto text-left">
                    <p class="variant">Pembelian</p>
                  </div>
                  <div class="col-6 my-auto text-right">
                    <p class="value">
                      {{ activities_subcription.product.fund_ccy
                      }}{{ formatPrice(activities_subcription.amount_nominal) }}
                    </p>
                  </div>
                </b-row>
              </div>
              <hr />
              <template v-if="activities_subcription.fee_sales != null">
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <template
                      v-if="activities_subcription.fee_type === 'PERCENT'"
                    >
                      <div class="col-6 my-auto text-left">
                        <p class="variant">Biaya Pembelian (Percent)</p>
                      </div>
                      <div class="col-6 my-auto text-right">
                        <p class="variant">
                          {{ activities_subcription.fee_sales }}%
                        </p>
                      </div>
                    </template>
                    <template
                      v-else-if="activities_subcription.fee_type === 'NOMINAL'"
                    >
                      <div class="col-6 my-auto text-left">
                        <p class="variant">Biaya Pembelian (Nominal)</p>
                      </div>
                      <div class="col-6 my-auto text-right">
                        <p class="variant">
                          {{ activities_subcription.product.fund_ccy
                          }}{{ formatPrice(activities_subcription.fee_sales) }}
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-6 my-auto text-left">
                        <p class="variant">Biaya Pembelian (Unit)</p>
                      </div>
                      <div class="col-6 my-auto text-right">
                        <p class="variant">
                          {{ activities_subcription.fee_sales }}
                        </p>
                      </div>
                    </template>
                  </b-row>
                </div>
                <hr />
                <div class="total-purchase">
                  <b-row class="mt-2 mb-4">
                    <div class="col-6 my-auto text-left">
                      <p class="variant">Total</p>
                    </div>
                    <div class="col-6 my-auto text-right">
                      <p class="value">
                        {{ activities_subcription.product.fund_ccy
                        }}{{
                          formatPrice(activities_subcription.amount_nominal)
                        }}
                      </p>
                    </div>
                  </b-row>
                </div>
              </template>

              <template v-else>
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <div class="col-6 my-auto text-left">
                      <p class="variant">Biaya Pembelian</p>
                    </div>
                    <div class="col-6 my-auto text-right">
                      <p
                        v-if="user.individual.sales_id != null"
                        class="variant"
                      >
                        {{
                          activities_subcription.product.fee_buy_text
                            ? activities_subcription.product.fee_buy_text
                            : "-"
                        }}
                      </p>
                      <p v-else class="value">Gratis</p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="total-purchase">
                  <b-row class="mt-2 mb-4">
                    <div class="col-6 my-auto text-left">
                      <p class="variant">Total</p>
                    </div>
                    <div class="col-6 my-auto text-right">
                      <p class="value">
                        {{ activities_subcription.product.fund_ccy
                        }}{{
                          formatPrice(activities_subcription.amount_nominal)
                        }}
                      </p>
                    </div>
                  </b-row>
                </div>
              </template>

              <b-button
                block
                :disabled="loading"
                @click="subscription()"
                class="btn-tertiary py-2"
              >
                <div v-if="loading">
                  <div class="spinner-border spinner-border-sm"></div>
                  Loading
                </div>
                <div v-else>Konfirmasi Pembayaran</div>
              </b-button>

              <!-- <b-button block v-b-modal.modal-success-checkout class="btn-tertiary">Konfirmasi Pembayaran</b-button> -->
              <!-- <b-button block to="/portfolio" class="btn-outline-primary"
              >Batalkan Transaksi</b-button
            > -->
            </b-form>
          </b-card>
        </b-row>
      </b-container>
    </template>
    <div class="modal-success-checkout">
      <b-modal
        id="checkModal"
        v-model="show"
        ref="checkModal"
        size="md"
        class="modal-success-checkout"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <img src="@/assets/img/icons/transactions/checked-blue.svg" alt="" />
          <h1 class="header-modal p-4 my-2">
            Pembelian Anda akan segera diproses
          </h1>
          <p>Sekarang Anda tinggal menunggu verifikasi.</p>
          <b-row class="justify-content-center">
            <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2"
              >Aktivitas Dalam Proses</b-button
            >
          </b-row>
        </div>
      </b-modal>
    </div>
    <!-- <template v-if="user.individual.sales_id != null">
          <b-modal
            id="checkModalTrx"
            v-model="checkTrx"
            ref="checkModalTrx"
            size="md"
            class="modal-check-trx"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
            :visible="visible"
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/icons/transactions/checked-blue.svg"
                alt=""
              />
              <h1 class="header-modal p-4 my-2">Bismillah</h1>
              <p>Sekarang Anda tinggal menunggu verifikasi.</p>
              <b-row class="justify-content-center">
                <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2"
                  >Aktivitas Dalam Proses</b-button
                >
              </b-row>
            </div>
          </b-modal>
        </template> -->
    <template>
      <vs-popup
        style="color: rgb(17, 24, 39)"
        background-color="rgba(255,255,255,.6)"
        title="Salin Nomor Rekening"
        :active.sync="copyAccountNumberPopup"
      >
        <p>Nomor rekening produk berhasil disalin</p>
      </vs-popup>
      <vs-popup
        style="color: rgb(17, 24, 39)"
        background-color="rgba(255,255,255,.6)"
        title="Salin Nominal"
        :active.sync="copyToClipboardPopup"
      >
        <p>Nominal Pembelian berhasil disalin</p>
      </vs-popup>
    </template>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import * as moment from "moment";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "SubscriptionCheckoutTransfer",
  metaInfo: {
    title: "Checkout Pembelian | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  data() {
    return {
      fund_code: "",
      code: "",
      activities_subcription: {
        created_at: "",
        amount_nominal: null,
        product: {
          rekening_product: {},
          investment_manager: {
            im_name: "",
          },
        },
      },
      product: [],
      banks: [],
      imageProof: null,
      messageProof: null,
      previewProof: null,
      errorsProof: null,
      base64Proof: "",
      date: moment(2460 * 60 * 1000),
      norek: "",
      needToUpload: false,
      show: false,
      visible: false,
      copyAccountNumberPopup: false,
      copyToClipboardPopup: false,
      loading: false,
      processing: false,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    time: function () {
      var currentDate = moment(this.activities_subcription.created_at);
      var tomorrow = moment(currentDate).add(1, "days");
      return tomorrow.format("DD MMM YYYY HH:mm:ss");
    },
    totalTrx: function () {
      const feeBuyTrx =
        (this.activities_subcription.fee_sales / 100) *
        parseInt(this.activities_subcription.amount_nominal);
      const total =
        parseInt(this.activities_subcription.amount_nominal) +
        parseInt(feeBuyTrx);
      return total;
    },
    feeBuy: function () {
      const feeBuyTrx =
        (this.activities_subcription.fee_sales / 100) *
        parseInt(this.activities_subcription.amount_nominal);
      return feeBuyTrx;
    },
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
    statusTrx: function () {
      const status = this.activities_subcription.status;
      return status;
    },
  },

  created() {
    this.fund_code = this.$route.params.fund_code;
    this.code = this.$route.params.code;
    this.fetchActivitiesSubcription();
    this.fetchFund();
  },

  mounted() {
    setInterval(() => {
      this.date = moment(this.date.subtract(1, "seconds"));
    }, 1000);
  },
  methods: {
    copyToClipboard() {
      const copyText = document.getElementById("copy-text").textContent;
      const textArea = document.createElement("textarea");
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
      this.copyToClipboardPopup = true;
    },
    copyAccountNumber() {
      const copyText = document.getElementById("copyAccountNumber").textContent;
      const textArea = document.createElement("textarea");
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
      this.copyAccountNumberPopup = true;
    },
    fileImageProof(event) {
      this.imageProof = event.target.files[0];
      this.previewProof = URL.createObjectURL(event.target.files[0]);

      // var reader = new FileReader();
      // reader.readAsDataURL(this.imageProof);
      // reader.onload = () => {
      //   this.base64Proof = reader.result;
      // };
      // reader.onerror = function (error) {
      //   console.log("Error: ", error);
      // };
    },
    resetImageProof() {
      this.imageProof = "";
      this.previewProof = "";
    },
    async fetchFund() {
      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      this.product = response.data.data;
    },
    async fetchActivitiesSubcription() {
      if (this.user.institutional != null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}subscription-get?code_buy=${this.code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.activities_subcription = response.data.data;
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}subscription-get?code_buy=${this.code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.activities_subcription = response.data.data;
      }
    },

    subscription: function () {
      if (!this.processing) {
        this.processing = true;
        let formData = new FormData();
        formData.append("receipt_path", this.imageProof);
        formData.append("code_buy", this.code);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        };
        instance
          .post(`${this.app_url}subscription-upload`, formData, axiosConfig)
          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            this.show = true;

            this.loading = !false;
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
            this.needToUpload = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}
.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-checkout {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.subscription-checkout {
  width: 600px;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}
.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    color: #e53e3e !important;
  }
}

.checkout-timer {
  background: #e60012;
  border-radius: 6px;
  padding: 6px;
  .variant {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: white;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
    color: white;
  }
}

.bank-transfer {
  margin-top: 40px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}
.bank-number {
  .variant {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.btn-copy-text {
  font-style: normal;
  background: transparent !important;
  border: none;
  font-weight: bold;
  font-size: 16px;

  color: #e60012;
  padding: 0px;
}

.btn-copy-text:hover {
  background: transparent !important;
}

.btn-copy-text:focus {
  box-shadow: none !important;
}

.btn-copy-text:active {
  background: transparent !important;
  outline: none !important;
  color: #e60012 !important;
}
.total-transfer {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #6b7280;
    margin-bottom: 0px !important;
  }
  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .btn-copy-text {
    font-style: normal;
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
    padding: 0px;
  }

  .btn-copy-text:focus {
    box-shadow: none !important;
  }

  .btn-copy-text:active {
    background: transparent !important;
    outline: none !important;
    color: #e60012 !important;
  }
}

.upload-proof {
  padding-left: 18px;
  padding-right: 18px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  background: #f3f4f6;
  color: dimgray;
  padding: 10px 10px;
  width: 510px; /* minimum height */
  position: relative;
  border-radius: 5px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.preview img {
  width: 510px; /* minimum height */
}

.reset-button {
  margin-left: 14px;
  font-size: 16px;
}

.dropbox {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #e60012;
  }
  .proof-icon {
    text-align: center;
    color: #e60012;
  }
}

.purchase-review {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.notes {
  margin-left: 16px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;

    color: #4b5563;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  padding-right: 18px;
  padding-left: 18px;

  .custodian-bank-name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;

    color: #111827;
  }
}

.custodian-bank-name {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  color: #111827;
}

.modal-success-chekout {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    text-align: center;
  }
}

.header-modal {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  h4,
  h3,
  span {
    font-size: 16px !important;
  }
  .back-nav {
    padding: 0px;
  }
  .dropbox {
    width: 100%;
  }
  .preview img {
    width: 100%;
  }
  .reset-button {
    font-size: 14px !important;
  }
  .header-modal {
    font-size: 16px !important;
  }
}
</style>
